@import "abstracts/vars";
@import "abstracts/mixins";
@import 'base/fontawesome/variables';
@import 'base/fontawesome/mixins';
.twitter {
    font-size: 2em;
    ul{
        display:flex;
        flex-wrap: wrap;
    }

    .twitter-logo{
        float:right;
        width:29px;
        height:29px;
        background: url('../images/icons/icon-twitter.svg' )no-repeat;
    }
    .twitter-follow-button {
        margin: 10px 0 0;
    }
    .tweet-item {
        flex-basis: 23%;
        flex-grow:1;
        min-width: 300px;
        padding: em(20px, 14px);
        margin: 0 1em 2em;
        @include border-basic();
        border-radius: 5px;
        position: relative;
        font-size: em(14px, 20px);

    }
    .tweet-header {
        overflow: hidden;

        .tweet-full-name{
            font-size: em(16px, 14px);
            font-weight: bold;
            color: $cl__black;
            display: block;
        }
    }
    .tweet-profile {
        display: block;
        float: left;
        text-decoration: none;
        font-size: 14px;
    }
    .tweet-avatar {
        width:36px;
        margin:0.7em 1em 0 0;
        float:left;
        height:32px;
        background: url('../images/icons/mirvac-logo-blue.svg') no-repeat;
    }
    .tweet-name{
        float:left;
        margin-bottom: 17px;
        line-height: 1.5em;
    }
    .tweet-datetime {
        display: block;
        text-decoration: none;
        color: #9e9e9e;
        font-size: em(14px, 14px);
    }
    .tweet-content {
        margin-top: 5px;
    }
    .tweet-text {
        margin-bottom: 1.3em;
        a { 
            font-size: 14px;
            text-decoration: underline;
        }
    }
    .tweet-footer {
        >ul li {
            display: inline;
            padding-left: 5px;
            &:first-child {
                padding: 0;
            }
        }
        a {
            text-decoration: none;
        }
    }

    .tweet-actions {
        a {
            margin: 10px 20px 8px 0;
            display: block;
            font-size: 1.4em;

            b{ display:none;}
        }
        .reply-action{
            &:before{
                background: url(../images/icons/ico-reply.svg) no-repeat center center;
                display: block;
                width: 18px;
                height: 18px;
                content: '';
            }

        }
        .retweet-action{
            &:before{
                background: url(../images/icons/ico-retweet.svg) no-repeat center center;
                display: block;
                width: 23px;
                height: 18px;
                content: '';
            }

            
        }
        .favorite-action{
            &:before{
                background: url(../images/icons/ico-like.svg) no-repeat center center;
                display: block;
                width: 18px;
                height: 18px;
                content: '';
            }
        }
    }
}
.twitter-follow-button{
    text-align:center;
    .btn.btn-link{
        color: #fff;
        background-color: #1DA1F1;
        padding: 13px 67px 11px 21px;
        &:after{
            background: $cl__white;
            margin-right:28px;
        }
        &:hover{
            color:$cl__white;
        }
    }
}
